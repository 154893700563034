import React, { useEffect, useState } from "react";
import { useWalletContext } from "../context/WalletProvider";
import { API_URL } from "../config";

const HistoryPage = () => {
  const { connected, usersTaprootAddress } = useWalletContext();
  const [requestIds, setRequestIds] = useState([]);

  const fetchHistory = async () => {
    fetch(`${API_URL}/history?address=${usersTaprootAddress}`)
      .then((response) => response.json())
      .then((data) => {
        data.forEach((request) => {
          request.timestamp = new Date(request.timestamp);
        });

        data.sort((a, b) => b.timestamp - a.timestamp);
        setRequestIds(data);
      })
      .catch((error) => {
        console.error("Error fetching status:", error);
      });
  };

  useEffect(() => {
    if (connected) {
      fetchHistory();
    }
    // eslint-disable-next-line
  }, [connected, usersTaprootAddress]); // Add id to the dependency array

  return (
    <div className="mt-10 text-center font-typewriter text-white">
      <h1 className="mb-8 text-4xl font-bold capitalize">HISTORY</h1>
      {requestIds.length > 0 && (
        <>
          <div>
            <table className="ml-auto mr-auto">
              <thead>
                <tr>
                  <th>REQUEST ID</th>
                  <th>TIMESTAMP</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {requestIds.map((requestId, index) => (
                  <tr key={index}>
                    <td className="pb-2 pr-4">
                      <a
                        href={`/status/${requestId.request_id}`}
                        className="underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {requestId.request_id}
                      </a>
                    </td>
                    <td className="pr-4">
                      {new Date(requestId.timestamp).toLocaleString()}
                    </td>
                    <td>{requestId.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default HistoryPage;
