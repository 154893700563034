let network = "Mainnet";
let api_url = "https://api.counterfeit.art";
switch (window.location.hostname) {
  case "staging.counterfeit.art":
    api_url = "https://staging.api.counterfeit.art";
    break;
  case "testnet.counterfeit.art":
    network = "Testnet";
    api_url = "https://testnet.api.counterfeit.art";
    break;
  default:
    break;
}
export const NETWORK = network;
export const API_URL = api_url;
