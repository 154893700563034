import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const InscriptionPage = () => {
  const { inscriptionId } = useParams();
  const [inscription, setInscription] = useState(null);

  const fetchInscription = async (inscriptionId) => {
    if (!inscriptionId) {
      // Handle the case where inscriptionId is undefined
      return;
    }

    try {
      const url = `https://api.hiro.so/ordinals/v1/inscriptions/${inscriptionId}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setInscription(data);
      console.log("Inscription:", data);
    } catch (error) {
      console.error("Error fetching inscription:", error);
    }
  };

  useEffect(() => {
    console.log(`calling fetchInscription(${inscriptionId})`);
    fetchInscription(inscriptionId);
  }, [inscriptionId]);

  const convertToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return date.toLocaleString(); // Convert to local date and time string
  };

  return (
    (inscription === null && <div>Loading...</div>) || ( // Show loading if inscription is null
      <div className="container mx-auto w-5/6 font-typewriter text-white md:w-9/12">
        <div className="flex flex-col items-start justify-center md:flex-row">
          <div className="w-full md:m-0 md:mr-8 md:w-2/5">
            <div style={{ width: "500px", height: "500px" }}>
              <iframe
                src={`https://ordinals.com/content/${inscription.id}`}
                title={inscription.number}
                width="100%"
                height="100%"
                style={{ outline: "none" }} // Remove outline
              ></iframe>
            </div>
          </div>

          <div className="mr-8 mt-8 w-full rounded-lg bg-white bg-opacity-5 p-4 text-left md:ml-8 md:mr-2 md:mt-0 md:w-3/5">
            <h1 className="mb-8 text-4xl font-bold capitalize">
              INSCRIPTION: {inscription.number}
            </h1>
            <div className="mb-4">
              <p className="mb-1">Inscription ID:</p>
              <p className="mb-4">{inscription.id}</p>
            </div>
            <div className="mb-4">
              <p className="mb-1">Height:</p>
              <p className="mb-4">{inscription.genesis_block_height}</p>
            </div>
            <div className="mb-4">
              <p className="mb-1">timestamp:</p>
              <p className="mb-4">
                {convertToLocalTime(inscription.genesis_timestamp)}
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-1">Location:</p>
              <p className="mb-4">{inscription.location}</p>
            </div>
            <div className="mb-4">
              <p className="mb-1">Address:</p>
              <p className="mb-4">{inscription.address}</p>
            </div>
            <div className="mb-4">
              <p className="mb-1">Amount:</p>
              <p className="mb-4">{inscription.amount}</p>
            </div>
            <div className="mb-4">
              <p className="mb-1">Content Type:</p>
              <p className="mb-4">{inscription.content_type}</p>
            </div>
            <div className="mb-4">
              <p className="mb-1">Recursion Refrences:</p>
              {inscription.recursion_refs && (
                <ul>
                  {inscription.recursion_refs.map((ref) => (
                    <li key={ref}>{ref}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default InscriptionPage;
