import React, { useState, useEffect } from "react";

const Countdown = ({ to }) => {
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const opensAtTime = new Date(to).getTime();
      const remainingTime = opensAtTime - now;

      if (remainingTime > 0) {
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60),
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setCountdown(
          `${String(days).padStart(2, "0")}d ${String(hours).padStart(
            2,
            "0",
          )}h ${String(minutes).padStart(2, "0")}m ${String(seconds).padStart(
            2,
            "0",
          )}s`,
        );
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [to]);

  return <>{countdown}</>;
};

export default Countdown;
