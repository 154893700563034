import React from "react";
import handleClaim from "../Utilities/handleClaim";
import grainbg from "../grain_bg.png";

const ClaimButton = () => {
  return (
    <button
      id="claim"
      className="w-100 mt-5 h-12 bg-cover bg-no-repeat font-typewriter"
      style={{ backgroundImage: `url(${grainbg})` }}
      onClick={handleClaim}
    >
      <p className="text-xl">CLAIM</p>
    </button>
  );
};

export default ClaimButton;
