import React from "react";

const LoadingGif = ({ style }) => {
  const defaultStyles = {
    display: "block",
    margin: "0 auto",
    width: "25%",
  };

  const combinedStyles = { ...defaultStyles, ...style };

  return (
    <img src="/images/cc_loading.gif" alt="Loading..." style={combinedStyles} />
  );
};

export default LoadingGif;
