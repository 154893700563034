import pinky from "../03-Pinkyvision-Release_the_Magic_1.webp";
import divide from "../divider2.png";
import logo from "../Logo_Outline_Still.png";
// import claim from "../claim.png";
// import grainbg from "../grain_bg.png";
import grainpurp from "../grainpurp.png";
import ClaimButton from "../components/ClaimButton.js";
import Countdown from "react-countdown";

function Experimental() {
  const targetDate1 = new Date();
  targetDate1.setHours(17);
  targetDate1.setMinutes(0);
  targetDate1.setSeconds(0);
  targetDate1.setMonth(11);
  targetDate1.setDate(13);

  const targetDate2 = new Date(targetDate1);
  targetDate2.setHours(targetDate2.getHours() + 5);

  const targetDate3 = new Date(targetDate2);
  targetDate3.setHours(targetDate3.getHours() + 5);
  const targetDate4 = new Date(targetDate3);
  targetDate4.setHours(targetDate3.getHours() + 5);
  return (
    <div className="mx-auto flex max-w-7xl flex-col lg:mt-10 lg:flex-row">
      {/* {pinky} div (visible on screens smaller than LG) */}
      <div className="lg:hidden">
        <div>
          <div className="ml-2 flex h-14 flex-row justify-between">
            <div className="text-md ml-2 flex-grow justify-between font-typewriter text-white">
              COUNTERFEIT CARDS
              <br /> SERIES 00 : CARD 03
              <br /> <br />
            </div>
            <div className="">
              <img src={logo} alt="logo" className="mr-5 w-10" />
            </div>
          </div>
        </div>

        <div>
          <img src={divide} alt="divider" className=" mb-1 h-1 w-screen" />
        </div>
        <img src={pinky} alt="Pinky" className="img w-screen" />
      </div>

      <div className=" mx-auto flex w-10/12 flex-col md:ml-5 lg:mr-14 lg:w-2/5 lg:items-start">
        <div className="w-100 hidden h-12 w-full flex-row justify-between lg:flex">
          <div className="text-md font-typewriter text-white">
            COUNTERFEIT CARDS
            <br /> SERIES 00 : CARD 03
            <br /> <br />
          </div>
          <div className="">
            <img src={logo} alt="logo" className=" mt-1 w-10" />
          </div>
        </div>
        <div>
          <img src={divide} alt="divider" className=" mb-5 mt-3 h-1 w-screen" />
        </div>
        <div className=" font-typewriter text-white ">
          <p className="text-3xl">Make Bitcoin Green Again</p>
          <p className="mb-3 text-lg">PRYZGOD</p>
          <p className="text-justify text-sm ">
            COUNTERFEIT CARDS SERIES 00 : CARD 19 SATRIA YUWAN MODERN
            CVLTBitcoin is like digital gold—intangible, decentralized, and
            limited in supply. It's a type of cryptocurrency, which is a form of
            digital or virtual currency that uses cryptography for security.{" "}
            <br></br>
            <br></br>
            What makes Bitcoin unique is its decentralized nature; it operates
            on a technology called blockchain, a distributed ledger enforced by
            a network of computers, or nodes. People can buy and sell bitcoins
            on various online platforms, and transactions are recorded on the
            blockchain.
          </p>
        </div>
        <div className=" flex flex-col text-white">
          <img src={divide} alt="divider" className=" mb-5 mt-5 h-1 w-screen" />
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col lg:w-60">
              <div className=" font-typewriter">
                <div className=" mb-2  border-white text-lg">
                  RELEASE DETAILS
                  <img src={divide} alt="divider" className="h-01 w-screen" />
                </div>
                <div className="flex flex-col justify-between text-sm">
                  <div
                    className="mb-3 flex h-10 flex-row justify-between bg-cover bg-no-repeat p-1"
                    style={{ backgroundImage: `url(${grainpurp})` }}
                  >
                    <div className=" mb-8 flex w-1/3 flex-col">
                      <div className="">ARTIFACT</div>
                      <div className="purpletxt text-xs">ALLOCATION : 0/1</div>
                    </div>
                    <div className="mb-6 w-1/3 text-center">
                      STARTS IN : <Countdown date={targetDate1} />
                    </div>
                    <div className="mb-6 w-1/3 text-end">0.00069 BTC</div>
                  </div>
                  <div className="  mb-3 flex h-10 flex-row justify-between p-1">
                    <div className=" mb-8 flex w-1/3 flex-col">
                      <div className="">COLLECTOR</div>
                      <div className="purpletxt text-xs">ALLOCATION : 0/1</div>
                    </div>
                    <div className="mb-6 w-1/3 text-center">
                      STARTS IN : <Countdown date={targetDate2} />
                    </div>
                    <div className="mb-6 w-1/3 text-end">0.00069 BTC</div>
                  </div>
                  <div className="  mb-3 flex h-10 flex-row justify-between p-1">
                    <div className=" mb-8 flex w-1/3 flex-col">
                      <div className="">TBD</div>
                      <div className="purpletxt text-xs">ALLOCATION : 0/1</div>
                    </div>
                    <div className="mb-6 w-1/3 text-center">
                      STARTS IN : <Countdown date={targetDate3} />
                    </div>
                    <div className="mb-6 w-1/3 text-end">0.00069 BTC</div>
                  </div>
                  <div className="  mb-3 flex h-10 flex-row justify-between p-1">
                    <div className=" mb-8 flex w-1/3 flex-col">
                      <div className="">PUBLIC</div>
                      {/* <div className="purpletxt text-xs">ALLOCATION : 0/1</div> */}
                    </div>
                    <div className="mb-6 w-1/3 text-center">
                      STARTS IN : <Countdown date={targetDate4} />
                    </div>
                    <div className="mb-6 w-1/3 text-end">0.00069 BTC</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mb-2 mt-3 flex   h-full flex-col items-center   justify-center border-2 border-white font-typewriter"
            style={{ height: "100%" }}
          >
            <p className="p-1 text-center text-lg">LIMITED EDITION</p>
          </div>
          <div className="mb-1 flex flex-row justify-between text-center font-typewriter">
            <div className="mb-3 flex w-3/6 flex-col items-center justify-center border-2 border-white pb-1 pl-5 pr-5 pt-1 text-center font-typewriter">
              <p className="purpletxt text-xs">SAT CLASS</p>
              <p>NAME PALINDROME</p>
            </div>
            <div className="width23 mb-3 flex flex-col items-center justify-center border-2 border-white pb-1 pl-5 pr-5 pt-1 text-center font-typewriter">
              <p className="purpletxt text-xs">MINTED</p>
              <p>69</p>
            </div>
            <div className="width23 mb-3 flex flex-col items-center justify-center border-2 border-white pb-1 pl-5 pr-5 pt-1 text-center font-typewriter">
              <p className="purpletxt text-xs">SUPPLY</p>
              <p>222</p>
            </div>
          </div>
        </div>
        {/* <button
          className="w-100 mt-5 h-12 bg-cover bg-no-repeat font-typewriter"
          style={{ backgroundImage: `url(${grainbg})` }}
        >
          <p className="text-xl">CLAIM</p>
        </button> */}
        <ClaimButton />
        <div className="mt-10">
          <div className="w-80 py-5"></div>
        </div>
      </div>

      {/* {pinky} div (visible on LG and above screens) */}
      <div className="hidden lg:mr-10 lg:block ">
        <img src={pinky} alt="Pinky" className="img min-w-" />
      </div>
    </div>
  );
}
export default Experimental;
