import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useWalletContext } from "../context/WalletProvider";
// import Countdown from "../components/Countdown";
import LoadingGif from "../components/atoms/LoadingGif";
import { API_URL } from "../config";
import Countdown from "../components/Countdown";
import { useNavigate } from "react-router-dom";

const StatusPage = () => {
  const navigate = useNavigate();
  const { someId } = useParams();
  const id = someId.replace(/[^a-z0-9-]/gi, "");
  const [requestId, setRequestId] = useState("");
  const [inscriptionIds, setInscriptionIds] = useState([]);
  const [status, setStatus] = useState(null);
  const [btcPaymentAddress, setBtcPaymentAddress] = useState(null);
  const [btcPaymentAmount, setBtcPaymentAmount] = useState(null);
  const [inscriptionTx, setInscriptionTx] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [paymentTransactions, setPaymentTransactions] = useState(null); // [txid, txid, txid
  const { PayInvoice } = useWalletContext();
  const [cardName, setCardName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [showPayButton, setShowPayButton] = useState(false);
  // const [timestamp, setTimestamp] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [testnet, setTestnet] = useState(false);

  const doPayInvoice = async (paymentAddress, paymentAmount) => {
    if (status !== "awaiting_payment") return;
    setShowPayButton(true);
    const payResult = await PayInvoice(paymentAddress, paymentAmount);
    if (
      status === "awaiting_payment" &&
      payResult &&
      payResult.error !== undefined
    ) {
      abandonRequest();
    } else {
      setShowPayButton(false);
    }
  };

  const abandonRequest = async () => {
    fetch(`${API_URL}/request?request_id=${requestId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        setStatus(data.status);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error abandoning request:", error);
      });
  };

  const getCardInfo = async () => {
    fetch(`${API_URL}/card?name=${cardName}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.displayName) {
          setDisplayName(data.displayName);
        }
      })
      .catch((error) => {
        console.error("Error fetching card info:", error);
      });
  };

  const getRequestId = async () => {
    fetch(`${API_URL}/request?request_id=${requestId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === status) return; // Don't update state if status hasn't changed
        setStatus(data.status);
        // setTimestamp(data.timestamp);
        if (data.status === "awaiting_payment") {
          const expiry = new Date(data.timestamp);
          expiry.setMinutes(expiry.getMinutes() + 30);
          setExpiry(expiry);
          // has expiry passed?
          if (expiry < new Date()) {
            setStatus("abandoned");
          }
          // doPayInvoice(data.btc_payment_address, data.btc_payment_amount);
        } else if (
          data.status === "confirming_payment" ||
          data.status === "inscribed" ||
          data.status === "abandoned" ||
          data.status === "processing" ||
          data.status === "pending"
        ) {
          const txs = getAddressTransactions(data.btc_payment_address);
          txs.then((txs) => {
            setPaymentTransactions(txs);
          });
        }
        if (data.inscription_id) {
          setInscriptionIds(JSON.parse(data.inscription_id));
          setInscriptionTx(data.inscription_tx.split(","));
        }
        if (data.btc_payment_address) {
          setBtcPaymentAddress(data.btc_payment_address);
          setBtcPaymentAmount(data.btc_payment_amount);
        }
        if (data.cardName) {
          setCardName(data.cardName);
        }
        if (data.quantity) {
          setQuantity(data.quantity);
        }
      })
      .catch((error) => {
        console.error("Error fetching status:", error);
      });
  };

  const getAddressTransactions = async (address) => {
    if (!address) return;
    let url = `https://mempool.space/api/address/${address}/txs`;
    if (address.startsWith("2")) {
      setTestnet(true);
      url = `https://mempool.space/testnet/api/address/${address}/txs`;
    }
    const response = await fetch(url);
    const data = await response.json();
    // console.log(data);
    return data;
  };

  useEffect(() => {
    setRequestId(id); // Set the requestId when id changes
  }, [id]); // Add id to the dependency array

  useEffect(() => {
    if (requestId) {
      getRequestId();
    }
    // eslint-disable-next-line
  }, [requestId]); // Add requestId to the dependency array

  useEffect(() => {
    if (cardName) {
      getCardInfo(cardName);
    }
    // eslint-disable-next-line
  }, [cardName]); // Add requestId to the dependency array

  useEffect(() => {
    if (status === "awaiting_payment") {
      doPayInvoice(btcPaymentAddress, btcPaymentAmount);
    }

    if (status !== "inscribed" && status !== "abandoned" && status !== null) {
      // Trigger getRequestId every 5 seconds
      const interval = setInterval(() => {
        getRequestId();
      }, 30000);

      // Cleanup: Clear the interval when component unmounts or status changes
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line
  }, [status]);

  const customStyles = {
    maxWidth: "30px",
    display: "inline-block",
  };

  return (
    <div className="mt-10 text-center font-typewriter text-white">
      <h1 className="mb-8 text-4xl font-bold capitalize">INSCRIPTION STATUS</h1>
      <div className="mb-4">
        <p className="mb-4">REQUEST ID:</p>
        <p className="mb-4">{id}</p>
      </div>
      <div className="mb-4">
        <p className="mb-4">REQUEST STATUS:</p>
        {status === "inscribed" ? (
          <p className="mb-4 font-bold text-green-500">Inscribed</p>
        ) : status === "abandoned" ? (
          <p className="mb-4 font-bold text-red-500">Abandoned</p>
        ) : status === "awaiting_payment" ? (
          <p className="mb-4 font-bold text-yellow-500">Awaiting Payment</p>
        ) : status === "processing" ? (
          <p className="mb-4 font-bold text-green-300">Processing</p>
        ) : status === "queued" ? (
          <p className="mb-4 font-bold text-green-300">Queued</p>
        ) : status === "confirming_payment" ? (
          <p className="mb-4 font-bold text-yellow-500">
            Your payment is being confirmed.
          </p>
        ) : (
          <p className="mb-4">{status}</p>
        )}
      </div>
      {paymentTransactions?.length > 0 && (
        <div className="mb-4">
          <p className="mb-4">PAYMENT TRANSACTIONS:</p>
          <ul className="mb-4 list-none pl-8">
            {paymentTransactions.map((transaction, index) => (
              <li key={index}>
                <a
                  href={
                    testnet
                      ? `https://mempool.space/testnet/tx/${transaction.txid}`
                      : `https://mempool.space/tx/${transaction.txid}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {transaction.txid}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {cardName && (
        <div className="mb-4">
          <p className="mb-4">INSCRIPTION:</p>
          <p className="mb-4">{displayName ? displayName : cardName}</p>
        </div>
      )}
      {quantity && (
        <div className="mb-4">
          <p className="mb-4">QUANTITY:</p>
          <p className="mb-4">{quantity}</p>
        </div>
      )}

      <div className="mb-4">
        {status === "pending" && (
          <>
            <p className="mb-4">Calculating, please wait...</p>
            <LoadingGif style={customStyles} />
          </>
        )}
        {status === "awaiting_payment" && (
          <>
            {showPayButton && (
              <button
                className="rounded-md bg-white px-4 py-2 text-black"
                onClick={() => {
                  PayInvoice(btcPaymentAddress, btcPaymentAmount);
                }}
                disabled={status !== "awaiting_payment"}
              >
                Pay
              </button>
            )}
            <div className="my-4">
              Expires in:
              <br />
              <Countdown to={expiry} /> (estimated)
            </div>
          </>
        )}
        {status === "confirming_payment" && <LoadingGif style={customStyles} />}
      </div>
      {inscriptionIds.length > 0 && (
        <>
          <div>
            <p className="mb-4">INSCRIPTION TRANSACTION:</p>
            <ul className="mb-4 list-none pl-8">
              {inscriptionTx.map((txId, index) => (
                <li key={index}>
                  <a
                    href={
                      testnet
                        ? `https://mempool.space/testnet/tx/${txId}`
                        : `https://mempool.space/tx/${txId}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {txId}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p className="mb-4">INSCRIPTION ID:</p>
            <ul className="mb-4 list-none pl-8">
              {inscriptionIds.map((insId, index) => (
                <li key={index}>
                  <a
                    href={
                      testnet
                        ? `https://testnet-explorer.ordinalsbot.com/inscription/${insId}`
                        : `https://ordinals.com/inscription/${insId}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {insId}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default StatusPage;
