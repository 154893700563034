import React from "react";
import Walelt from "./wallet";
import NotificationButton from "./NotificationButton";
import { NETWORK } from "../config";

const notifications = [];

const Header = () => {
  return (
    <header className="mb-8 flex items-center justify-between p-4 text-white">
      <div
        id="title"
        class="flex items-center justify-center font-typewriter font-bold"
      >
        <ul class="flex flex-row gap-12">
          <li>
            <a href="/">
              <img
                class="logo h-auto w-44 object-scale-down"
                src={
                  NETWORK === "Testnet"
                    ? "/images/counterfeit-testnet.png"
                    : "/images/counterfeit.png"
                }
                alt="Counterfeit"
              />
            </a>
          </li>
          {/* <li className=" text-sm">LAUNCHPAD</li>
          <li className=" text-sm">GALLERY</li>
          <li className=" text-sm">MARKETPLACE</li> */}
        </ul>
      </div>
      <div className="flex items-center space-x-4">
        <NotificationButton notifications={notifications} />
        <Walelt />
      </div>
    </header>
  );
};

export default Header;
