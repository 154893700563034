import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useWalletContext } from "../context/WalletProvider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Wallet = () => {
  const {
    connected,
    connectWallet,
    connectUnisatWallet,
    disconnectWallet,
    usersTaprootAddress,
    connectXverseWallet,
  } = useWalletContext();

  const goToDashboard = () => {
    window.location.href = "/dashboard";
  };

  return (
    <>
      {!connected ? (
        <Menu
          as="div"
          className="relative inline-block text-left text-xs md:text-lg"
        >
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 font-typewriter text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              CONNECT
              <ChevronDownIcon
                className="-mr-1 h-4 w-4 text-gray-400 md:h-7 md:w-7"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1 font-typewriter">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2",
                      )}
                      onClick={connectWallet}
                    >
                      Hiro
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2",
                      )}
                      onClick={connectXverseWallet}
                    >
                      Xverse
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2",
                      )}
                      onClick={connectUnisatWallet}
                    >
                      Unisat
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <Menu
          as="div"
          className="relative inline-block text-left text-xs md:text-lg"
        >
          <div>
            <Menu.Button className="inline-flex  w-full justify-center gap-x-1.5 rounded-md bg-custom-color px-3 py-2 font-typewriter text-white shadow-sm ring-1 ring-inset hover:text-gray-200">
              ...{usersTaprootAddress.slice(-5)}
              <ChevronDownIcon
                className="-mr-1 h-4 w-4 text-gray-200 md:h-7 md:w-7"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1 font-typewriter">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2",
                      )}
                      onClick={goToDashboard}
                    >
                      Dashboard
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2",
                      )}
                      onClick={disconnectWallet}
                    >
                      DISCONNECT
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </>
  );
};

export default Wallet;
