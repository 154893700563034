import React from "react";

const CardClaimHeaderImg = (props) => {
  const headerImage = props.headerImage;

  return (
    <div>
      {headerImage !== "" && (
        <img
          src={headerImage}
          alt="Header"
          className="mx-auto"
          // style={{ width: "512px" }}
        />
      )}
      <img
        src="/images/divider.png"
        alt="Header"
        className="mx-auto mt-8"
        // style={{ width: "512px" }}
      />
    </div>
  );
};

export default CardClaimHeaderImg;
