import React, { useState } from "react";
import { useWalletContext } from "../../context/WalletProvider";
import HistoryPage from "../HistoryPage";

const DashboardPage = () => {
  const { connected, connectWallet } = useWalletContext();
  const [selectedItem, setSelectedItem] = useState("History");

  const handleNavItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <>
      {connected ? (
        <div className="flex border-t">
          <LeftNav
            selectedItem={selectedItem}
            onNavItemClick={handleNavItemClick}
          />
          <div className="mb-8 flex-grow">
            {selectedItem === "History" && <HistoryPage />}
          </div>
        </div>
      ) : (
        <div className="flex h-full flex-col items-center justify-center">
          <h1 className="mb-4 text-2xl font-semibold">Connect Wallet</h1>
          <button
            type="button"
            className="rounded-md bg-custom-color px-4 py-2 text-white"
            onClick={connectWallet}
          >
            Connect
          </button>
        </div>
      )}
    </>
  );
};

const LeftNav = ({ selectedItem, onNavItemClick }) => {
  return (
    <div className="w-64 border-r px-6 py-4 font-typewriter text-white ">
      <h2 className="mb-4 text-lg font-semibold">Dashboard</h2>
      <ul>
        <li>
          <button
            type="button"
            className={`block w-full py-2 pl-2 text-left ${
              selectedItem === "History" ? "bg-custom-color text-white" : ""
            } hover:bg-gray-200 hover:text-black`}
            onClick={() => onNavItemClick("History")}
            title="View your transaction history"
          >
            History
          </button>
        </li>
      </ul>
    </div>
  );
};

export default DashboardPage;
