import React, { useState } from "react";
import { TrashIcon, LinkIcon } from "@heroicons/react/20/solid";
import { useNotification } from "../context/NotificationContext";

const NotificationButton = () => {
  const { notifications, setNotifications } = useNotification();
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const clearNotifications = () => {
    setNotifications([]); // Clear notifications by setting to an empty array
  };

  // Check if the notifications array is empty
  const isNotificationsEmpty = notifications.length === 0;

  return !isNotificationsEmpty ? (
    <div className="relative inline-block font-typewriter">
      <button
        className="rounded-md bg-white px-4 py-2 font-typewriter text-black"
        onClick={toggleNotifications}
      >
        Status ({notifications.length})
      </button>
      {showNotifications && (
        <div className="absolute right-0 mt-2 w-64 rounded bg-white p-4 text-black shadow-lg">
          <div className="mb-2 flex items-center justify-between">
            <h3 className="text-lg font-semibold">Notifications</h3>
            <button
              onClick={clearNotifications}
              className="text-gray-500 hover:text-red-600"
            >
              <TrashIcon className="h-4 w-4" />
            </button>
          </div>
          <ul>
            {notifications.map((notification, index) => (
              <li key={index} className="mb-2">
                {notification.linkUrl && (
                  <button
                    onClick={() => window.open(notification.linkUrl, "_blank")}
                    className="text-blue-500 underline hover:no-underline focus:outline-none"
                  >
                    <LinkIcon className="mr-2 inline h-4 w-4" />
                  </button>
                )}
                {notification.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  ) : null;
};

export default NotificationButton;
