import React, { useState, useContext, createContext, useEffect } from "react";
import { getAddress, sendBtcTransaction } from "sats-connect";
import { API_URL, NETWORK } from "../config";

const WalletContext = createContext();

export const useWalletContext = () => {
  return useContext(WalletContext);
};

const useWallet = () => {
  const [connected, setConnected] = useState(false);
  const [usersTaprootAddress, setUsersTaprootAddress] = useState("");
  const [wallet, setWallet] = useState("");
  const [usersPaymentAddress, setUsersPaymentAddress] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [usersPublicKey, setUsersPublicKey] = useState("");

  const connectWallet = async () => {
    try {
      const accounts = await window.btc?.request("getAddresses");
      const usersTaprootAddress = accounts.result.addresses.find(
        (address) => address.type === "p2tr",
      );
      setUsersTaprootAddress(usersTaprootAddress.address);
      setConnected(true);
      setWallet("hiro");
      // Store the connected state in localStorage
      localStorage.setItem("connected", true);
      localStorage.setItem("wallet", "hiro");
      localStorage.setItem("accounts", usersTaprootAddress.address);
    } catch (e) {
      console.log("connect failed: ", e);
    }
  };

  const connectXverseWallet = async () => {
    const getAddressOptions = {
      payload: {
        purposes: ["ordinals", "payment"],
        message: "Address for receiving Ordinals and payments",
        network: {
          type: NETWORK,
        },
      },
      onFinish: (response) => {
        const address = response.addresses[0].address;
        const usersPaymentAddress = response.addresses[1].address;
        localStorage.setItem("connected", true);
        localStorage.setItem("wallet", "xverse");
        localStorage.setItem("accounts", address);
        localStorage.setItem("usersPaymentAddress", usersPaymentAddress);
        // get first item of response.addresses
        setUsersTaprootAddress(address);
        setUsersPaymentAddress(usersPaymentAddress);
        setConnected(true);
        setWallet("xverse");
      },
      onCancel: () => console.log("Request canceled"),
    };

    await getAddress(getAddressOptions);
  };

  const connectUnisatWallet = async () => {
    try {
      let accounts = await window.unisat.requestAccounts();
      let usersPublicKey = await window.unisat.getPublicKey();
      setUsersTaprootAddress(accounts[0]);
      setUsersPublicKey(usersPublicKey);
      setConnected(true);
      setWallet("unisat");
      // Store the connected state in localStorage
      localStorage.setItem("connected", true);
      localStorage.setItem("wallet", "unisat");
      localStorage.setItem("accounts", accounts);
      localStorage.setItem("usersPublicKey", usersPublicKey);
    } catch (e) {
      console.log("connect failed: ", e);
    }
  };

  const PayInvoice = async (paymentAddress, paymentAmount) => {
    const data = null;
    switch (wallet) {
      case "unisat":
        try {
          let tx = await window.unisat.sendBitcoin(
            paymentAddress,
            paymentAmount,
            data,
          );
          console.log(tx);
        } catch (e) {
          return { error: e };
        }
        break;
      case "hiro":
        try {
          const resp = await window.btc?.request("sendTransfer", {
            from: usersPaymentAddress,
            address: paymentAddress,
            amount: paymentAmount,
          });
          return resp.result;
        } catch (e) {
          // console.log("send transaction failed: ", e);
          return e;
        }
      case "xverse":
        try {
          const sendBtcOptions = {
            payload: {
              network: {
                type: NETWORK,
              },
              recipients: [
                {
                  address: paymentAddress,
                  amountSats: paymentAmount,
                },
              ],
              senderAddress: usersPaymentAddress,
            },
            onFinish: (response) => {
              return response;
            },
            onCancel: () => {
              throw new Error("Request canceled"); // Explicitly throw an error
            },
          };
          await sendBtcTransaction(sendBtcOptions);
        } catch (error) {
          return { error: error };
        }
        break;
      default:
        console.log("wallet not connected");
    }
  };

  const disconnectWallet = () => {
    setConnected(false);
    setUsersTaprootAddress(null);
    console.log("Wallet disconnected");
    // Clear the connected state from localStorage
    localStorage.removeItem("connected");
    localStorage.removeItem("accounts");
    localStorage.removeItem("wallet");
    localStorage.removeItem("usersPaymentAddress");
    setConnected(false);
    setWallet(null);
    setUsersTaprootAddress(null);
    setUsersPaymentAddress(null);
  };

  const doAuthentication = async () => {
    try {
      const response = await fetch(
        `${API_URL}/login?address=${usersTaprootAddress}`,
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const prepLoginData = await response.json();
      const challenge = prepLoginData.signMessage;

      try {
        let signedMessage = await window.unisat.signMessage(
          challenge,
          "bip322-simple",
        );
        const res = await fetch(`${API_URL}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: usersTaprootAddress,
            signedMessage: signedMessage,
            publicKey: usersPublicKey,
          }),
        });
        console.log(res);
        setIsAuthenticated(true);
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // check local storage for wallet informaion
    if (typeof window !== "undefined") {
      const walletConnected = localStorage.getItem("connected");
      const walletName = localStorage.getItem("wallet");
      const usersTaprootAddress = localStorage.getItem("accounts");
      const usersPaymentAddress = localStorage.getItem("usersPaymentAddress");
      if (walletConnected && walletName && usersTaprootAddress) {
        setConnected(walletConnected);
        setWallet(walletName);
        setUsersTaprootAddress(usersTaprootAddress);
        setUsersPaymentAddress(usersPaymentAddress);
      }
    }
  }, []);

  return {
    connectWallet,
    connectUnisatWallet,
    disconnectWallet,
    connected,
    usersTaprootAddress,
    usersPaymentAddress,
    PayInvoice,
    connectXverseWallet,
    isAuthenticated,
    doAuthentication,
  };
};

const WalletProvider = ({ children }) => {
  const {
    connected,
    usersTaprootAddress,
    usersPaymentAddress,
    connectWallet,
    connectUnisatWallet,
    disconnectWallet,
    PayInvoice,
    connectXverseWallet,
    isAuthenticated,
    doAuthentication,
  } = useWallet();

  return (
    <WalletContext.Provider
      value={{
        connected,
        usersTaprootAddress,
        usersPaymentAddress,
        connectWallet,
        connectUnisatWallet,
        disconnectWallet,
        PayInvoice,
        connectXverseWallet,
        isAuthenticated,
        doAuthentication,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
